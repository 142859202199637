// Import all the language configurations
import malayalam from "./malayalam.json";
import hindi from "./hindi.json";
import kannada from "./kannada.json";
import bangla from "./bangla.json";
import tamil from "./tamil.json";
import telugu from "./telugu.json";
import marathi from "./marathi.json";
import gujarati from "./gujarati.json";
import odia from "./odia.json";
import punjabi from "./punjabi.json";
import { LanguageConfigType } from "@/types";

// Variable for getting the currently selected language
let languageConfig: LanguageConfigType | undefined;

// Match with environment variable and set the selected language
switch (process.env.NEXT_PUBLIC_LANGUAGE) {
  case "malayalam":
    languageConfig = malayalam;
    break;
  case "hindi":
    languageConfig = hindi;
    break;
  case "kannada":
    languageConfig = kannada;
    break;
  case "bangla":
    languageConfig = bangla;
    break;
  case "tamil":
    languageConfig = tamil;
    break;
  case "telugu":
    languageConfig = telugu;
    break;
  case "marathi":
    languageConfig = marathi;
    break;
  case "gujarati":
    languageConfig = gujarati;
    break;
  case "odia":
    languageConfig = odia;
    break;
  case "punjabi":
    languageConfig = punjabi;
    break;
  default:
    languageConfig = undefined;
    break;
}

// Export config of the current selected language
export default languageConfig;
